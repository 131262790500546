import React from "react"
import { graphql, navigateTo } from 'gatsby'
import styled from 'styled-components'
import { MDXRenderer } from "gatsby-plugin-mdx"
import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Layout } from "./Layout"
import SEO from './seo'
import { Wrapper } from './Wrapper'
import { colors } from "../constants"
import { fontsName } from '../fonts/fontsName'
import { mobile } from '../utils/medias'

const Container = styled.div`
  background-color: #fff;
  box-sizing: border-box;
  height: calc(100% - 20px);
  left: 0px;
  margin: 10px;
  overflow: scroll;
  padding: 20px;
  position: absolute;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  top: 0px;
  width: calc(100% - 20px);

  h1 {
    font-size: 50px;
    font-family: ${fontsName.KnockoutHTF93UltmtHeviwt};
    letter-spacing: 3.75px;
    margin-top: 50px;
    text-transform: uppercase;
    font-weight: bold;

    ${mobile(`
      font-size: 35px;
    `)}
  }

  h2 {
    font-size: 24px;
    margin: 50px 0 20px 0;
    font-weight: bold;
    font-family: ${fontsName.KnockoutHTF71FullMiddlewt};
    letter-spacing: 1.8px;
  }

  p {
    margin: 5px 0;
  }

  .gatsby-resp-image-background-image {
    display: none !important;
  }

  .gatsby-resp-image-wrapper {
    margin: 50px 0;
    img {
      max-width: 100%;
    }
  }
`

const InfoItem = styled.div`
  font-family: ${fontsName.KnockoutHTF32JuniorCruiserwt};
  letter-spacing: 1.2px;
  line-height: 26px;
  margin: 30px 0;

  b {
    font-family: ${fontsName.KnockoutHTF71FullMiddlewt};
    /* font-weight: bold; */
  }
`

const Content = styled.div`
  font-family: ${fontsName.KnockoutHTF32JuniorCruiserwt};
  font-size: 16px;
  letter-spacing: 1.2px;
  line-height: 26px;

  a {
    color: ${colors.linkColor};
    text-decoration: none;
  }
`

const Close = styled.div`
  align-items: center;
  background-color: ${colors.background};
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 25px;
  height: 110px;
  justify-content: center;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 100px;

  @media screen and (max-width: 640px) {
    height: 60px;
    width: 50px;
    font-size: 18px;
  }
`

export default function PageTemplate({ data: { mdx } }) {
  return (
    <Layout>
      <Container>
        <Wrapper>
          <SEO title={mdx.frontmatter.title} />
          <h1>{mdx.frontmatter.title}</h1>
          <div>
            {mdx.frontmatter.client && (
              <InfoItem>
                <b>Client</b>: {mdx.frontmatter.client}
              </InfoItem>
            )}
            {mdx.frontmatter.role && (
              <InfoItem>
                <b>Rôle</b>: {mdx.frontmatter.role}
              </InfoItem>
            )}
            {mdx.frontmatter.tech && (
              <InfoItem>
                <b>Technologies</b>: {mdx.frontmatter.tech}
              </InfoItem>
            )}
            {mdx.frontmatter.date && (
              <InfoItem>
                <b>Date</b>: {mdx.frontmatter.date}
              </InfoItem>
            )}
          </div>
          <Content>
            {
              // @ts-ignore
              <MDXRenderer>
                {mdx.body}
              </MDXRenderer>
            }
          </Content>
        </Wrapper>
      </Container>
      <Close onClick={() => { navigateTo('/') }}>
        <FontAwesomeIcon icon={faTimes} />
      </Close>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        client
        role
        tech
        date
      }
    }
  }
`
